export default [
    {
        label: '평가도구',
        field: 'docId',
        sort: true
    },
    {
        label: '영상제목',
        field: 'title',
        sort: false
    },
    {
        label: '재생시간',
        field: 'time',
        sort: false
    }
];
